var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    type: "year",
                    default: "today",
                    label: "기준년도",
                    name: "wasteYear",
                  },
                  model: {
                    value: _vm.searchParam.wasteYear,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "wasteYear", $$v)
                    },
                    expression: "searchParam.wasteYear",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "grid",
          attrs: {
            title: "월별 폐기물 발생 및 처리 목록",
            tableId: "grid",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.grid.data,
            columnSetting: false,
            isFullScreen: false,
            usePaging: false,
            expandAll: true,
          },
          on: { linkClick: _vm.linkClick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLREG", icon: "add" },
                        on: { btnClicked: _vm.linkClick },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }